<template>
  <li :key="item._uid" v-if="!item.is_disabled" class="item relative m-0 p-0 md:flex" @mouseleave="isMobile ? null : closeSubMenu()" :class="{'bg-grey-30': item._uid === activeIndex}">
    <a class="md:flex text-h5 text-white font-bold uppercase leading-loose tracking-xl py-4 md:py-0 md:px-7 items-center link"
      href="javascript:void(0);" @click.prevent="parentLinkAction(item, item._uid)" @mouseenter="isMobile ? null : openSubMenu(item._uid)">
      <span>{{ item.linkName }}</span>
      <i class="ss-icon leading-none inline-block align-middle ss-navigatedown menu__icon" v-if="item.subMenu" :class="{'menu__icon--active': item._uid === activeIndex && isMobile }"></i>
    </a>
    <transition name="slide-down" @enter="enter" @before-leave="!isMobile ? null : beforeLeave($event)">
      <ul v-if="(item.subMenu && item._uid === activeIndex) || item.subMenu && forceOpen" class="sub-menu min-w-full relative md:absolute left-0 border-l-15 border-r-15 border-grey-30 md:border-l-10 md:border-r-10 bg-black top-full md:border-t-10 md:border-b-10 md:py-2">
        <li v-if="item.caption">
          <span class="font-serif text-brown-2 block italic text-h3 leading-none font-normal mb-md leading-sm caption md:pl-5 md:pr-2">
            {{ item.caption }}
          </span>
        </li>
        <sb-render :key="item._uid" v-for="item in item.subMenu" :item="item" />
      </ul>
    </transition>
  </li>
</template>
<script>
import {
  Blok
} from 'src/modules/vsf-storyblok-module/components'
export default {
  extends: Blok,
  name: 'HeaderMenuDropdown',
  data() {
    return {
      activeIndex: null,
      isMobile: false,
      desktopPoint: 768,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile)
  },
  mounted: function () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.checkIsMobile)
      this.checkIsMobile()
    })
  },
  computed: {
    forceOpen () {
      return this.$store.state.ui.forceOpen
    }
  },
  methods: {
    animate: function ({
      duration,
      draw,
      timing
    }) {
      let start = performance.now()
      requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;
        let progress = timing(timeFraction)
        draw(progress);
        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        }
      });
    },
    enter: function (el, done) {
      if (this.isMobile) {
        this.animate({
          duration: 400,
          timing: function (timeFraction) {
            return timeFraction;
          },
          draw: function (progress) {
            el.style.paddingTop = progress * 20 + 'px';
            el.style.paddingBottom = progress * 10 + 'px';
            el.style.borderBottomWidth = progress * 10 + 'px';
          }
        })
      }
      done();
    },
    beforeLeave: function (el) {
      this.animate({
        duration: 0,
        timing: function (timeFraction) {
          return timeFraction;
        },
        draw: function (progress) {
          el.style.paddingTop = progress * 0 + 'px';
          el.style.paddingBottom = progress * 0 + 'px';
          el.style.borderBottomWidth = progress * 0 + 'px';
        }
      })
    },
    parentLinkAction(link, index) {
      if (link.url) {
        this.$router.push(this.localizedRoute(link.url))
      } else if (this.isMobile) {
        this.toggleSubMenu(index, link.subMenu)
      } else {
        return false
      }
    },
    checkIsMobile() {
      this.windowWidth = document.documentElement.clientWidth
      this.isMobile = this.windowWidth < this.desktopPoint
    },
    closeSubMenu() {
      this.activeIndex = null
    },
    openSubMenu(index) {
      this.activeIndex = index
    },
    toggleSubMenu(index, subMenu) {
      if (subMenu) {
        return this.activeIndex === index ?
          this.activeIndex = null :
          this.activeIndex = index
      }
    }
  }
}

</script>
<style lang="scss" scoped>
$screen-md: 768px;

.slide-down-enter-active {
  transition: all 0.7s ease-in;

  @screen md {
    transition: opacity 0.25s ease;
  }
}

.slide-down-leave-active {
  transition: all 0.7s cubic-bezier(0, 1, 0.5, 1);

  @screen md {
    transition: opacity 0.25s ease;
  }
}

.slide-down-enter-to,
.slide-down-leave {
  @screen md {
    opacity: 1;
  }
}

.slide-down-enter,
.slide-down-leave-to {
  @screen md {
    opacity: 0;
  }
}

.sub-menu {
  @screenmdl {
    width: 250px;
  }
}

@media screen and (max-width: #{$screen-md - 1}) {

  .slide-down-enter-to,
  .slide-down-leave {
    max-height: 400px;
    overflow: hidden;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .sub-menu {
    .caption {
      @apply text-center;
    }
  }

  .link {
    @apply font-normal text-average leading-h6 tracking-lg block;
  }

  .menu {
    &__icon {
      &--active {
        &:before {
          content: '\F500';
        }
      }
    }
  }
}

</style>
